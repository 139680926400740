const darkModeColors = {
    backgroundColor: '#121212',
    textColorStrong: '#e3e3e3',
    textColorMedium: '#c1c1c1',
    textColorLight: '#898989',
};
const themes = [
    {
        name: 'dark-blue',
        colors: Object.assign({ primaryColor: '#2D799F' }, darkModeColors),
    },
    {
        name: 'dark-orange',
        colors: Object.assign({ primaryColor: '#C76838' }, darkModeColors),
    },
    {
        name: 'dark-green',
        colors: Object.assign({ primaryColor: '#409F2D' }, darkModeColors),
    },
    {
        name: 'dark-rose',
        colors: Object.assign({ primaryColor: '#BF364D' }, darkModeColors),
    },
    {
        name: 'dark-purple',
        colors: Object.assign({ primaryColor: '#5065CE' }, darkModeColors),
    },
];
export default themes;
