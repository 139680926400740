const setCssVariables = (node, variables) => {
    for (const [name, value] of Object.entries(variables)) {
        node.style.setProperty(`--${name}`, value);
    }
};
const cssVariables = (node, variables) => {
    setCssVariables(node, variables);
    return {
        update(variables) {
            setCssVariables(node, variables);
        },
    };
};
export default cssVariables;
